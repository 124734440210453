import React, {
  useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { Box, IconButton } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowBackIos';
import ArrowRightIcon from '@mui/icons-material/ArrowForwardIos';
import { useRecursiveTimeout } from '../../hooks';
import { LightGalleryContext } from '../../contexts/lightgallery';
import { SiteContext } from '../../contexts';

export default function Carousel({
  id, autoplayInterval = -1, video, playButton, lightbox, columns, images, spacing, sx, children: [{ props: { children } }],
  galleryGroupName, galleryGroupOrder, galleryOnly, ...props
}) {
  const galleryGroupId = useRef(`${Math.floor(Math.random() * 10000)}_${new Date().valueOf()}`).current;
  const { registerGroup, openGallery } = useContext(LightGalleryContext);
  const { site } = useContext(SiteContext);
  const filteredChildren = children.filter((x) => !!x);

  const [viewportRef, embla] = useEmblaCarousel({ skipSnaps: false, startIndex: Math.floor(filteredChildren.length / 2), ...props });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  const autoplay = useCallback(() => {
    if (!embla) return;
    if (embla.canScrollNext()) {
      embla.scrollNext();
    } else {
      embla.scrollTo(0);
    }
  }, [embla]);

  const { play, stop } = useRecursiveTimeout(autoplay, autoplayInterval);

  const scrollNext = useCallback(() => {
    if (!embla) return;
    embla.scrollNext();
  }, [embla, stop]);

  const scrollPrev = useCallback(() => {
    if (!embla) return;
    embla.scrollPrev();
  }, [embla, stop]);

  useEffect(() => {
    if (!embla) return;

    onSelect();
    embla.on('select', onSelect);
    embla.on('pointerDown', stop);
    embla.on('pointerUp', play);
  }, [embla, onSelect]);

  useEffect(() => {
    play();
  }, [play]);

  // const fetchVideoMetadata = async (ids) => {
  //   const response = await fetch(`/api/site/${site.id}/vimeo-videos`, {
  //     method: 'post',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ ids }),
  //   });

  //   if (!response.ok) {
  //     throw new Error(await response.text());
  //   }
  //   const { files: items } = await response.json();

  //   return items;
  // };

  useEffect(() => {
    if (!images.length || !site.language.id) {
      return;
    }

    (async () => {
      if (video) {
        // const videoIds = images.filter((x) => x.video).map((x) => {
        //   const parts = x.video.split('/');
        //   return parts[parts.length - 1].replace(/\?.*/g, '');
        // });

        // const [videoMetadata] = await Promise.all([
        //   fetchVideoMetadata(videoIds),
        // ]);

        registerGroup(galleryGroupId, galleryGroupName || 'Image gallery', galleryGroupOrder || 0, images.map((item, idx) => ({
          ...item,
          id: item.id,
          src: item.video,
          thumb: item.video, // videoMetadata[idx].thumbnail,
          slideTitle: item[`slideTitle_${site.language.id}`],
          slideText: item[`slideText_${site.language.id}`],
        })));

        return;
      }

      registerGroup(galleryGroupId, galleryGroupName || 'Video gallery', galleryGroupOrder || 0, images.map((item) => ({
        ...item,
        id: item.id,
        src: `/api/uploads/w_2000,c_limit/${item.image}`,
        thumb: `/api/uploads/w_200,c_limit/${item.image}`,
        slideTitle: item[`slideTitle_${site.language.id}`],
        slideText: item[`slideText_${site.language.id}`],
      })));
    })();
  }, [images, site.language.id]);

  if (!filteredChildren.length || galleryOnly) {
    return null;
  }

  const randomId = new Date().valueOf();
  return (
    <Box id={id} className="embla" sx={{ position: 'relative', ...sx }}>
      <Box
        className="embla__viewport"
        sx={{
          overflow: 'hidden',
          width: '100%',
          '& .is-draggable': {
            cursor: 'grab',
          },
          '& .is-dragging': {
            cursor: 'grabbing',
          },
        }}
        ref={viewportRef}
      >
        <Box
          className="embla__container"
          sx={{
            display: 'grid',
            gridAutoColumns: columns,
            columnGap: 1,
            gridAutoFlow: 'column',
            userSelect: 'none',
            WebkitTouchCallout: 'none',
            KhtmlUserSelect: 'none',
            WebkitTapHighlightColor: 'transparent',
          }}
        >
          {children.map((child, idx) => (
            <Box
              key={child.key}
              className="embla__slide"
              sx={{
                position: 'relative',
                minWidth: '50%',
              }}
            >
              {video ? (
                <>
                  <Box
                    component="div"
                    sx={{ cursor: 'pointer' }}
                    className={`lightbox-${randomId}-image`}
                    onClick={() => {
                      openGallery(galleryGroupId, images[idx].id);
                    }}
                  >
                    { child }
                  </Box>
                </>
              ) : (
                <Box
                  component="div"
                  className={`lightbox-${randomId}-image`}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    openGallery(galleryGroupId, images[idx].id);
                  }}
                >
                  {child}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      </Box>
      {prevBtnEnabled && (
        <IconButton
          sx={{ position: 'absolute', left: 10, top: '50%', transform: 'translate(0, -50%)', cursor: 'pointer' }}
          onClick={scrollPrev}
        >
          <ArrowLeftIcon sx={{ fontSize: 38, color: 'white', filter: 'drop-shadow( 0px 0px 1px rgba(0, 0, 0, .9))' }} />
        </IconButton>
      )}
      {nextBtnEnabled && (
        <IconButton
          sx={{ position: 'absolute', right: 10, top: '50%', transform: 'translate(0, -50%)', cursor: 'pointer' }}
          onClick={scrollNext}
        >
          <ArrowRightIcon sx={{ fontSize: 38, color: 'white', filter: 'drop-shadow( 0px 0px 1px rgba(0, 0, 0, .9))' }} />
        </IconButton>
      )}
    </Box>
  );
}
